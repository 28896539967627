html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Stop user from scrolling from outside of the screen */

@media only screen and (max-width: 900px) {
  .App {
    overflow-x: hidden;
  }
}

::placeholder {
  text-transform: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-calendar-picker-indicator {
  background-color: #438ef2aa;
  padding: 5px;
  margin-right: 5px;
  border-radius: 2px;
  border: 1px solid #222222;
  cursor: pointer;
}
